import request from '@/utils/request';

export function fetchNotifications({ page, per_page, sort, dir }) {
  return request({
    url: 'member_notifications',
    method: 'get',
    params: {
      page,
      per_page,
      sort,
      dir
    }
  });
}

export function showNotification(id) {
  return request({
    url: `member_notifications/${id}`,
    method: 'get'
  });
}

export default {
  fetchNotifications,
  showNotification
};
