<template>
  <div class="main-content white">
    <div class="content-block">
      <div class="notify-list">
        <router-link
          v-for="notification in dataList"
          :key="notification.id"
          class="notify-block"
          :class="{ un: notification.is_read === 0 }"
          :to="{
            name: 'MemberNotificationContent',
            params: { notification_id: notification.id }
          }"
        >
          <div class="n-content">
            <div class="n-title">
              {{ notification.title }}
            </div>
            <div class="n-time">{{ notification.created_at }}</div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchNotifications } from '@/api/notifications';
import paginateMixn from '@/mixins/paginate';

export default {
  mixins: [paginateMixn],
  data() {
    return {
      sortBy: {
        text: '',
        sort: 'created_at',
        dir: 'desc'
      },
      api: fetchNotifications
    };
  },
  computed: {
    query() {
      return {
        page: this.page,
        per_page: this.per_page,
        sort: this.sortBy.sort,
        dir: this.sortBy.dir
      };
    }
  }
};
</script>
